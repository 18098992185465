import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import { useEffect, useRef, useState } from "react";
import loop480 from "../assets/videoclips/websiteLoop_16_9_V3_480p.mp4";
import loop720 from "../assets/videoclips/websiteLoop_16_9_V3_720p.mp4";
import lowResPoster from "../assets/images/vlcsnap-2024-11-10-17h35m23s954.png";

type Props = {
  lng: string;
};

const HeroVideoLoop = ({ lng }: Props) => {
  const [t, setT] = useState(() => (key: string) => key);
  const [isHighResLoaded, setIsHighResLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    i18next
      .use(HttpBackend)
      .init({
        lng: lng,
        fallbackLng: "de",
        debug: false,
        backend: {
          loadPath: "/locales/{{lng}}/translation.json",
        },
      })
      .then(() => {
        setT(() => i18next.t.bind(i18next));
      });
  }, [lng]);

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      if (entry.isIntersecting && videoRef.current) {
        videoRef.current.load();
        videoRef.current.play();
        loadHigherResolution();
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });
    if (videoRef.current) observer.observe(videoRef.current);

    return () => {
      if (videoRef.current) observer.unobserve(videoRef.current);
    };
  }, []);

  const loadHigherResolution = () => {
    const highResVideo = document.createElement("video");
    highResVideo.src = loop720;
    highResVideo.oncanplaythrough = () => {
      setIsHighResLoaded(true);
    };
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="max-w-[1024px]">
          <div className="video-container">
            <video
              ref={videoRef}
              className={`video-player ${
                isHighResLoaded ? "high-res" : "low-res"
              }`}
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              poster={lowResPoster.src}
              controls={false}
            >
              <source
                src={loop480}
                type="video/mp4"
                media="(max-resolution: 480p)"
              />
              {isHighResLoaded && <source src={loop720} type="video/mp4" />}
            </video>
          </div>
        </div>
      </div>
      <style>
        {`
          .video-container {
            position: relative;
            width: 100%;
            overflow: hidden;
            background-color: black;
            }
            .video-player {
              width: 100%;
              height: auto;
              object-fit: cover;
              transition: filter 0.3s ease-in-out;
              }
              .low-res {
                filter: blur(10px);
                }
                .high-res {
                  filter: blur(0);
                  }
                  `}
      </style>
    </>
  );
};

export default HeroVideoLoop;
